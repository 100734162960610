import React, { useState } from 'react';
import './index.css';
import { Buffer } from 'buffer'

const AIChat = () => {
    const [GPTQuery, SetGPTQuery] = useState('');
    const [buttonText, setButtonText] = useState('Ask');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [pastDialog, setPastDialog] = useState([])

    const generateChat = async () => {
        if (isButtonDisabled) {
            return
        }
        SetGPTQuery(GPTQuery)
        setButtonText('Answering...')
        setIsButtonDisabled(true)

        fetch(`https://api.favorai.com/chat/${GPTQuery}`, {
            method: 'GET',
            responseType: 'stream'
        }).then((response) => {
            const reader = response.body.getReader()
            let fullResponse = ''
            reader.read().then(function processText({ done, value }) {
                if (done) {
                    setButtonText('Ask Something Else')
                    setIsButtonDisabled(false)
                    return
                }
                let formattedText = Buffer.from(value).toString('utf8')
                if (formattedText.includes('\\n')) {
                    console.log(formattedText)
                    const part = Buffer.from(formattedText).toString('utf8')
                    formattedText = part.replace(/\\n/g, '')
                }
                fullResponse += formattedText
                setPastDialog([<tr key={pastDialog.length}><td><p className="gptQuery">{GPTQuery} ({new Date().toISOString().replace('T',' ').replace('Z','')})</p>{fullResponse}</td></tr>].concat(pastDialog))

                return reader.read().then(processText)
            })
        }).catch((err) => {
            console.log(err)
            alert('Something went wrong. Please reload or try again later.')
            window.location.reload()
        })
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            generateChat()
        }
    }

    return (
        <div className="container">
            <h1 style={{ "color": "white" }}>Chat</h1>
            <input
                className="input"
                type="text"
                placeholder="What do you want to ask?"
                onInput={e => { SetGPTQuery(e.target.value) }}
                onKeyDown={handleKeyPress}
                required
            />
            <button className="button" disabled={isButtonDisabled} onClick={generateChat}>
                <span className="buttonText">{buttonText}</span>
            </button>
            <br />
            <table className="chatResponseTable">
                <tbody>
                    {pastDialog.slice(0,4)}
                </tbody>
            </table>
        </div>
    );
};

export default AIChat;