import React from 'react';
import { Helmet } from 'react-helmet';
import logo from './logo.svg';
import './App.css';
import './index.css';
import AIActivitySelector from './AIActivitySelector';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAVOR AI</title>
        <meta name="description" content="Login or sign up to access Favor AI" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Content-Security-Policy" content="default-src 'self' api.favorai.com; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline'; img-src 'self' data:" />
        <link rel="icon" type="image/png" href="favicon.png" sizes="32x32" />
      </Helmet>
      <img src={logo} className="App-logo" alt="logo" height="100" width="100" />
      <header className="App-header">
        FAVOR AI
      </header>
      <AIActivitySelector></AIActivitySelector>
    </div>
  );
}

export default App;