import React, { useState } from 'react';
import './index.css';
import logo from './assets/loading.gif'

const AIDraw = () => {
  const [prompt, setPrompt] = useState('');
  const [buttonText, setButtonText] = useState('Generate DALLE-2 Image');
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [sizeStr, setSizeStr] = useState('128px');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const generateImage = async () => {
    setGeneratedImageUrl(logo)
    setPrompt(prompt)
    setButtonText('Drawing...')
    setIsButtonDisabled(true)
    setSizeStr('128px')

    fetch(`https://api.favorai.com/draw/${prompt}`, {
      method: 'GET',
    }).then((response) => {
      return response.json()
    }).then((data) => {
      const imgUrl = ('data:image/png;base64,' + data[0]?.b64_json) || '/static/media/logo.d10ef52f.svg'
      updateImgDim()
      setGeneratedImageUrl(imgUrl)
      setButtonText('Generate Another')
      setIsButtonDisabled(false)
    }).catch((err) => {
      console.log(err)
      alert('Something went wrong. Please reload or try again later.')
      window.location.reload()
    })
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      generateImage()
    }
  }

  const updateImgDim = () => {
    const { innerWidth: width, innerHeight: height } = window;
    let imgSizeStr = '512px'
    if (width < 512 || height < 512) {
      imgSizeStr = '66%'
    }
    setSizeStr(imgSizeStr)
  }

  return (
    <div className="container">
      <h1>Draw</h1>
      <input
        className="input"
        type="text"
        placeholder="prompt"
        onInput={e => { setPrompt(e.target.value) }}
        onKeyDown={handleKeyPress}
        required
      />
      <button className="button" disabled={isButtonDisabled} onClick={generateImage}>
        <span className="buttonText">{buttonText}</span>
      </button>
      <br />
      {generatedImageUrl && <img width={sizeStr} src={generatedImageUrl} alt={prompt} />}
    </div>
  );
};

export default AIDraw;