import React, { useState } from 'react';
import './index.css';
import AIChat from './AIChat';
import AIDraw from './AIDraw';

const AIActivitySelector = () => {
    const [activity, setActivity] = useState('Chat');

    const toggleActivity = () => {
        let otherActivity
        if (activity === 'Chat') {
            otherActivity = 'Draw'
        } else if (activity === 'Draw') {
            otherActivity = 'Chat'
        }
        setActivity(otherActivity)
    }

    return (
        <div>
            {/* <button className="button" onClick={toggleActivity('Draw')}><span>{activity}</span></button>
            {activity === 'Chat' ? <AIChat></AIChat> : <AIDraw></AIDraw>} */}
            <AIChat></AIChat>
            <AIDraw></AIDraw>
        </div>
    );
};

export default AIActivitySelector;